import React, { useState, useEffect } from "react";
import "../scss/ContactPage.scss";
import Seo from "../components/seo/seo";

import axios from "../http/axios";

import BubblesBgHeader from "../shared/bubbles-bg-header/BubblesBgHeader";

import ContactForm from "../components/contact-form/ContactForm";

const ContactPage = () => {
  const [recaptcha, setRecaptcha] = useState([]);
  useEffect(() => {
    axios
      .get("/recaptcha-settings")
      .then((r) => {
        setRecaptcha(r.data);
      })
      .catch((err) => { });
  }, []);

  return (
    <div className="contact-page">
      <Seo title="Contact -" />
      <BubblesBgHeader text="Contact" />
      <div className="content-block container mt-5">
        <div className="row">
          <div className="col-lg-3">
            <div className="contacts-list d-flex flex-column gap-4">
              <div className="contacts-list__item">
                <div className="contact-field">
                  <span className="contact-field__title">Brian Lasky:</span>
                  &nbsp;
                  <span className="contact-field__value">Teacher</span>
                </div>
                <div className="contact-field">
                  <span className="contact-field__title">Email:</span>
                  &nbsp;
                  <a href="#" className="contact-field__value cursor-pointer" role="button">
                    info@gotbubbles.com
                  </a>
                </div>
                <div className="contact-field">
                  <span className="contact-field__title">Phone:</span>
                  &nbsp;
                  <span className="contact-field__value">310-505-9231</span>
                </div>
              </div>
              <div className="contacts-list__item">
                <div className="contact-field">
                  <span className="contact-field__title">Katja:</span>
                  &nbsp;
                  <span className="contact-field__value">Scheduling</span>
                </div>
                <div className="contact-field">
                  <span className="contact-field__title">Email:</span>
                  &nbsp;
                  <a href="#" className="contact-field__value cursor-pointer" role="button">
                    info@gotbubbles.com
                  </a>
                </div>
                <div className="contact-field">
                  <span className="contact-field__title">Phone:</span>
                  &nbsp;
                  <span className="contact-field__value">
                    424-209-SWIM (7946)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <ContactForm recaptcha={recaptcha} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
