import React, { useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import axios from "../../http/axios";

import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({ recaptcha }) => {
  const reRef = useRef();

  const [successfulSubmission, setSuccessfulSubmission] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    phone: Yup.string().required("Phone is required"),
    subject: Yup.string(),
    message: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);

  const { errors } = formState;

  const onSubmit = async (data) => {
    let token;
    if (recaptcha && recaptcha?.enabled) {
      token = await reRef.current.executeAsync();
      data.recaptchaToken = token;
    }
    await axios.post("/contact-us", data).then((res) => {
      if (res.status === 200) {
        setSuccessfulSubmission(true);
        reset({ name: "", email: "", phone: "", subject: "", message: "" });
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="contact-form-wrapper">
        <h2 className="mt-3">CONTACT US</h2>
        <p>
          <strong>For the quickest response, please email.</strong>
        </p>
        <p>
          I am more than happy to answer any questions you have about my
          teaching styles, methods, or any concerns you may have regarding your
          child. I am, however, unable to take phone calls during business hours
          since I am in the pool. Any specific questions/calls directed to me
          will be returned after hours.
        </p>
        <p>
          In the meantime, feel free to direct all scheduling and general
          questions about the program to my assistant, Katja. She is very
          familiar with my teaching and will be able to answer most questions.
        </p>
        <div className="form-row">
          <div className="form-group col input-wrapper my-3">
            <label htmlFor="name">Your Name &#40;required&#41;</label>
            <input
              name="name"
              id="name"
              type="text"
              aria-required="true"
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback validation-error">
              {errors.name?.message}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col input-wrapper mb-3">
            <label htmlFor="email">Your Email &#40;required&#41;</label>
            <input
              name="email"
              id="email"
              type="text"
              aria-required="true"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback validation-error">
              {errors.email?.message}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col input-wrapper mb-3">
            <label htmlFor="phone">Your Telephone Number &#40;required&#41;</label>
            <input
              name="phone"
              id="phone"
              type="text"
              aria-required="true"
              {...register("phone")}
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback validation-error">
              {errors.phone?.message}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col input-wrapper mb-3">
            <label htmlFor="subject">Your Subject</label>
            <input
              name="subject"
              id="subject"
              type="text"
              {...register("subject")}
              className={`form-control ${errors.subject ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback validation-error">
              {errors.subject?.message}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col input-wrapper mb-3">
            <label htmlFor="message">Your Message</label>
            <textarea
              name="message"
              id="message"
              {...register("message")}
              className={`form-control ${errors.message ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback validation-error">
              {errors.message?.message}
            </div>
          </div>
        </div>
        {successfulSubmission && (
          <div
            className="col-12"
            style={{
              border: "2px solid #46b450",
              fontSize: "15px",
              padding: "0.2em 1em",
            }}
          >
            Thank you htmlFor your message. It has been sent.
          </div>
        )}
        <div className="d-flex justify-content-center">
          <button type="submit" className="cursor-pointer mb-6 mt-5">
            Send
          </button>
        </div>
      </form>
      &nbsp;
      {recaptcha && recaptcha?.enabled && (
        <div>
          <ReCAPTCHA
            sitekey={recaptcha.publicKey}
            size="invisible"
            ref={reRef}
          />
        </div>
      )}
    </>
  );
};

export default ContactForm;
